import FF from "../../components/forms/FormField.module.css";
import P from "./PricesView.module.css";

import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type { PriceFieldsBase, PricingHistory } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue } from "jotai/react";
import { useMemo, useState } from "react";
import type { Settings } from "react-slick";
import { findLast, isDeepEqual } from "remeda";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { MonthYearDate } from "../../components/l10n/DateFormat.tsx";
import { showModal } from "../../components/modal/registerModal.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { Separator } from "../../components/Separator.tsx";
import { siteCurrencyAtom } from "../../config.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { cn } from "../../utils/classNames.ts";
import { produce } from "../../utils/immer.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AddEditItemModal } from "./AddEditItemModal.tsx";
import { ChangeCurrentPricesModal } from "./ChangeCurrentPricesModal.tsx";
import { DeleteScheduledPricesModal } from "./DeleteScheduledPricesModal.tsx";
import { LocationRadios } from "./LocationRadios.tsx";
import { PackagePrices } from "./PackagePrices.tsx";
import { historyPricesAtom, scheduledCreateMutation } from "./pricesQuery.ts";
import { PricesTable } from "./PricesTable.tsx";
import { PricesTitle } from "./PricesTitle.tsx";
import type { PricingResourceType } from "./pricingResourceTypes.tsx";
import { ScheduleNewPricesModal } from "./ScheduleNewPricesModal.tsx";
import { SchedulePricesSelect } from "./SchedulePricesSelect.tsx";
import { WarningModal } from "./WarningModal.tsx";

export interface PriceFieldsBaseWithType extends PriceFieldsBase {
    resource_type: PricingResourceType;
    num: number;
}

function getStartOfCurrentMonth() {
    const date = new Date();
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
    //return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function PricesView() {
    //#region Hooks
    const updateMutation = useStandardMutation(scheduledCreateMutation);

    // NB: Memoize the date to avoid it changing while the user is editing (in case the time crosses to the next month)
    const currentStamp = useMemo(() => getStartOfCurrentMonth(), []);

    const loadedHistory = useSuspenseQueryAtom(historyPricesAtom);
    const siteCurrency = useAtomValue(siteCurrencyAtom);

    // NB: Since the value of `data` can change (due to eg. query refresh), we need to to only use it when it's set and clear it when we want to re-fetch
    const [modifiedHistory, setModifiedHistory] = useState<PricingHistory | undefined>(undefined);

    // If not modified, use live query data
    const history = modifiedHistory ?? loadedHistory;
    const setHistory = (fn: (data: PricingHistory) => void) => {
        setModifiedHistory((data) => produce(data ?? history, fn));
    };

    const dates = Object.keys(history);

    /** The date labeled as "Current" */
    const defaultDate = useMemo(
        () =>
            notNull(
                findLast(Object.keys(loadedHistory), (date) => apiDatetimeToDate(date) <= currentStamp),
                "TODO: Handle no dates",
            ),
        [loadedHistory, currentStamp],
    );
    const [selectedDate, setSelectedDate] = useState(defaultDate);

    const [savedLocation, setSelectedLocation] = useState<string | undefined>();
    /** Wrapper around the user-selected location to ensure it's valid */
    const selectedLocation = useMemo(() => {
        const validLocations = Object.keys(history[selectedDate][siteCurrency]);
        return savedLocation && validLocations.includes(savedLocation) ? savedLocation : validLocations[0];
    }, [selectedDate, savedLocation, history, siteCurrency]);

    /** The date for the un-saved scheduled prices. We only allow adding one set of scheduled prices at a time. */
    const [schedulingDate, setSchedulingDate] = useState<string | undefined>();
    //#endregion

    const selectedPrices = history[selectedDate];

    const isModified = !isDeepEqual(loadedHistory, history);

    const pricesEditable = useMemo(() => {
        const prevStamp = selectedDate ? apiDatetimeToDate(selectedDate) : new Date(0);
        let nextDate;
        const index = dates.indexOf(selectedDate);
        if (index >= 0 && index < dates.length - 1) {
            nextDate = dates[index + 1];
        }

        if (nextDate) {
            const nextStamp = apiDatetimeToDate(nextDate);
            if (prevStamp <= currentStamp && nextStamp > currentStamp) {
                return true;
            } else if (nextStamp > currentStamp) {
                return true;
            }
        } else {
            if (prevStamp > currentStamp) {
                return true;
            }
        }
        return false;
    }, [selectedDate, dates, currentStamp]);

    function setActiveDate(date: string) {
        if (isModified) {
            showModal(
                <WarningModal
                    modalAction={() => {
                        // Clear any changes
                        setModifiedHistory(undefined);
                        setSchedulingDate(undefined);
                        setSelectedDate(date);
                    }}
                />,
            );
        } else {
            setSelectedDate(date);
        }
    }

    function addScheduledPrices(date: string) {
        setHistory((data) => {
            if (schedulingDate) {
                // Move the currently scheduled prices to the new date
                data[date] = data[schedulingDate];
                delete data[schedulingDate];
            } else {
                // Default to the current prices
                data[date] = notNull(data[defaultDate]);
            }
        });

        setSchedulingDate(date);
        setSelectedDate(date);
    }

    function deleteScheduledPrices() {
        setHistory((data) => {
            delete data[selectedDate];
            //delete data[schedulingDate!];
        });
        setSchedulingDate(undefined);

        setSelectedDate(defaultDate);
    }

    const slides = dates.map((date, index) => {
        const dateStamp = apiDatetimeToDate(date);
        const next = dates[index + 1];
        let isCurrent = false;
        let isFuture = false;

        let title = "Previous Prices";
        const prevTitle = <MonthYearDate date={dateStamp} />;
        let finishTitle = <>...</>;

        if (next) {
            const nextStamp = apiDatetimeToDate(next);
            const currentMonth = nextStamp.getMonth();
            nextStamp.setMonth(currentMonth - 1);
            if (next !== schedulingDate) finishTitle = <MonthYearDate date={nextStamp} />;

            if (dateStamp <= currentStamp && nextStamp >= currentStamp) {
                title = "Current Prices";
                isCurrent = true;
            } else if (nextStamp >= currentStamp) {
                title = "Scheduled Prices";
                isFuture = true;
            }
        } else {
            title = "Scheduled Prices";
            isFuture = true;
        }

        const isSelected = selectedDate === date;

        return (
            <div
                key={date}
                className={cn(P.Slide, isCurrent && P.Current, isFuture && P.Future, isSelected && P.Active)}
                onClick={() => setActiveDate(date)}
            >
                <div className={P.Content}>
                    <div className={cn(isSelected ? P.White : "color-muted", "font-size-small pb-4")}>{title}</div>
                    {isFuture && date === schedulingDate ? (
                        <div className={P.Schedule}>
                            <SchedulePricesSelect
                                onChange={(value) => addScheduledPrices(value)}
                                value={schedulingDate}
                                disabledKeys={dates.filter((item) => item !== schedulingDate)}
                            />
                        </div>
                    ) : (
                        <div className="font-size-subtitle pt-2">
                            {prevTitle} - {finishTitle}
                        </div>
                    )}

                    {isFuture && (
                        <div className={P.Delete}>
                            {schedulingDate ? (
                                <WButton
                                    className={cn(isSelected && P.White)}
                                    size="xs"
                                    color="primary"
                                    variant="ghost"
                                    icon="jp-trash-icon"
                                    action={() => deleteScheduledPrices()}
                                />
                            ) : (
                                <WModal
                                    button={
                                        <WButton
                                            className={cn(isSelected && P.White)}
                                            size="xs"
                                            color="primary"
                                            variant="ghost"
                                            icon="jp-trash-icon"
                                            action={undefined}
                                        />
                                    }
                                >
                                    <DeleteScheduledPricesModal onDelete={() => deleteScheduledPrices()} item={date} />
                                </WModal>
                            )}
                        </div>
                    )}
                    {isCurrent && <div className={P.Banner}>Current</div>}
                </div>
            </div>
        );
    });

    const settings: Settings = {
        dots: false,
        infinite: false,
        initialSlide: dates.indexOf(defaultDate),
    };

    function addLocation(locKey: string) {
        setHistory((data) => {
            data[selectedDate][siteCurrency][locKey] = notNull(selectedPrices)[siteCurrency].DEFAULT;
        });
        setSelectedLocation(locKey);
    }

    function deleteCurrentLocation() {
        setSelectedLocation("DEFAULT");
        setHistory((data) => {
            delete data[selectedDate][siteCurrency][selectedLocation];
        });
    }

    async function onSubmit(date?: string) {
        await updateMutation.mutateAsync({ id: date ? date : selectedDate, body: selectedPrices });
        // Clear any changes so we can re-fetch the data
        setModifiedHistory(undefined);
        setSchedulingDate(undefined);
    }

    function onCancel() {
        setModifiedHistory(undefined);
        if (selectedDate === schedulingDate) {
            setSelectedDate(defaultDate);
        }
        setSchedulingDate(undefined);
    }

    function onAddPriceRow(rows: PriceFieldsBase[], resourceType: string) {
        setHistory((data) => {
            const locData = data[selectedDate][siteCurrency][selectedLocation];

            const resource = notNull(locData.find((item) => item.resource_type === resourceType));
            resource.resource_prices = rows;
        });
    }

    const disabledKeys = selectedPrices ? Object.keys(selectedPrices[siteCurrency]) : [];
    const usableResourceTypes = selectedPrices[siteCurrency][selectedLocation]
        .filter((type) => !type.resource_prices.length)
        .map((type) => type.resource_type);

    const stateKey = `${selectedLocation};${selectedDate}`;

    return (
        <>
            <AdminTitle title="Prices" />

            <p className="p-2 color-muted">
                All configured platform prices are listed here.
                <br />
                You can view and schedule price updates for different locations.
            </p>

            <div className={P.Slides}>
                <WCarousel settings={settings} slideWidth={200}>
                    {slides}
                    <div className={P.Slide}>
                        <WModal
                            button={
                                <WButton
                                    isDisabled={!!schedulingDate}
                                    className={P.New}
                                    color="primary"
                                    variant="dashed"
                                    size="lg"
                                    icon="jp-icon-add"
                                    action={undefined}
                                >
                                    Schedule New Prices
                                </WButton>
                            }
                        >
                            <ScheduleNewPricesModal
                                onChange={(value: string) => addScheduledPrices(value)}
                                disabledKeys={Object.keys(history)}
                            />
                        </WModal>
                    </div>
                </WCarousel>
            </div>

            <div className="pt-3">
                <Separator />
            </div>

            <ContentPane>
                {!!selectedDate && (
                    <PricesTitle
                        selectedKey={selectedDate}
                        dates={dates}
                        currentStamp={currentStamp}
                        isModified={isModified}
                    />
                )}

                <LocationRadios
                    isDisabled={!pricesEditable}
                    disabledKeys={disabledKeys}
                    onAdd={(value) => addLocation(value)}
                    onChange={(locKey) => setSelectedLocation(locKey)}
                    onDelete={() => deleteCurrentLocation()}
                    selectedLocation={selectedLocation}
                    selectedPrices={selectedPrices}
                />

                <PackagePrices
                    priceList={selectedPrices ? selectedPrices : undefined}
                    currency={siteCurrency}
                    location={selectedLocation}
                />

                <PricesTable
                    key={stateKey}
                    selectedPrices={selectedPrices}
                    selectedLocation={selectedLocation}
                    isDisabled={!pricesEditable}
                    onChangePrices={(prices) => {
                        setHistory((history) => {
                            history[selectedDate] = prices;
                        });
                    }}
                />

                <div className="pt-3 flex gap-0.5rem">
                    <WModal
                        button={
                            <WModalButton
                                isDisabled={!pricesEditable || !usableResourceTypes.length}
                                className="w-full"
                                color="primary"
                                size="lg"
                                variant="dashed"
                                label="Add New Item"
                                icon="jp-icon-add"
                            />
                        }
                    >
                        <AddEditItemModal
                            usableTypes={usableResourceTypes}
                            onChange={(rows, resourceType) => onAddPriceRow(rows, resourceType)}
                        />
                    </WModal>
                </div>
            </ContentPane>

            <Separator />

            <div className={FF.FormActions}>
                {defaultDate === selectedDate ? (
                    <WModal
                        button={
                            <WModalButton
                                isDisabled={!pricesEditable || !isModified}
                                color="primary"
                                variant="basic"
                                size="md"
                                label="Publish"
                            />
                        }
                    >
                        <ChangeCurrentPricesModal onChange={(date) => onSubmit(date)} />
                    </WModal>
                ) : (
                    <WButton
                        color="primary"
                        variant="basic"
                        size="md"
                        isDisabled={!pricesEditable || !isModified}
                        action={async () => await onSubmit()}
                    >
                        Publish
                    </WButton>
                )}

                <WButton color="default" variant="basic" size="md" isDisabled={!isModified} action={() => onCancel()}>
                    Cancel
                </WButton>
            </div>
        </>
    );
}
