import LR from "../../components/LeftRight.module.css";

import { useMemo } from "react";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect } from "../../components/forms/WSelect.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { MonthYearDate } from "../../components/l10n/DateFormat.tsx";
import { calculateScheduleDates } from "./calculateScheduleDates.tsx";

export function SchedulePricesSelect({
    items,
    onChange,
    disabledKeys,
    value,
}: {
    items?: string[];
    onChange: (value: string) => void;
    disabledKeys: string[];
    value: string;
}) {
    const itemsWithDefault = useMemo(() => items ?? calculateScheduleDates(), [items]);

    return (
        <WSelect
            value={value}
            getKey={(item) => item}
            items={itemsWithDefault}
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select date"
            disabledKeys={disabledKeys}
            getTextValue={(item) => item}
            onChange={onChange}
        >
            {(item) => {
                return (
                    <LeftRight
                        icon={<MaskIcon className="jp-calendar-icon size-0.75rem" />}
                        title={<MonthYearDate date={item} />}
                    />
                );
            }}
        </WSelect>
    );
}
