import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { PricingHistory, ScheduledPricingBody } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export const queryKey: QueryKey = ["pricing_history"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<PricingHistory> => {
            return getResponseData(await client.GET("/pricing/list", { signal }));
        },
    });
}

export const historyPricesAtom = atomFromStandardQueryOptions(getQuery);

//#region Mutations

export const scheduledCreateMutation = adminMutation((apiClient) => ({
    entity: "prices",
    operation: "create",
    requestFn: async ({ id, body }: { id: string; body: ScheduledPricingBody }) =>
        await apiClient.PUT("/pricing/list/{id}", {
            params: { path: { id: String(id) } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const scheduledDeleteMutation = adminMutation((apiClient) => ({
    entity: "prices",
    operation: "delete",
    requestFn: async ({ id }: { id: string }) =>
        await apiClient.DELETE("/pricing/list/{id}", {
            params: { path: { id: String(id) } },
        }),
    invalidateQueryKey: queryKey,
}));

//#endregion
