import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { MonthYearDate } from "../../components/l10n/DateFormat.tsx";

export function PricesTitle({
    selectedKey,
    dates,
    currentStamp,
    isModified,
}: {
    selectedKey: string;
    dates: string[];
    currentStamp: Date;
    isModified?: boolean;
}) {
    const prevStamp = apiDatetimeToDate(selectedKey);
    let next;
    const index = dates.indexOf(selectedKey);
    if (index >= 0 && index < dates.length - 1) next = dates[index + 1];
    let isCurrent = false;

    let title = "Previous Prices";
    const prevTitle = <MonthYearDate date={selectedKey} />;
    let finishTitle = <>...</>;

    if (next) {
        const nextStamp = apiDatetimeToDate(next);
        const currentMonth = nextStamp.getMonth();
        nextStamp.setMonth(currentMonth - 1);
        finishTitle = <MonthYearDate date={nextStamp} />;

        if (prevStamp <= currentStamp && nextStamp >= currentStamp) {
            title = "Current Prices";
            isCurrent = true;
        } else if (nextStamp >= currentStamp) {
            title = "Scheduled Prices";
        }
    } else {
        title = "Scheduled Prices";
    }

    return (
        <>
            <div className="font-size-subtitle">
                {title}
                {!isCurrent && (
                    <>
                        : {prevTitle} - {finishTitle}
                    </>
                )}
                {isModified && "(unpublished)"}
            </div>

            {prevStamp > currentStamp ? (
                <p className="color-muted pt-1">
                    These prices will take effect on the 01 <MonthYearDate date={selectedKey} />, 00:00 UTC
                </p>
            ) : null}
        </>
    );
}
