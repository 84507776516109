export function calculateScheduleDates() {
    const date = new Date();
    const startDate = new Date(date.setMonth(date.getMonth() + 1));
    const endDate = new Date(date.setFullYear(date.getFullYear() + 2));

    const items: string[] = [];
    if (startDate < endDate) {
        for (
            let current = new Date(startDate);
            current < endDate;
            current = new Date(current.setMonth(current.getMonth() + 1))
        ) {
            items.push(toMonthString(current));
        }
    }

    return items;
}

export function toMonthString(current: Date): string {
    return `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, "0")}`;
}
