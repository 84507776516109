import T from "../../components/forms/TextField.module.css";

import type { PriceFieldsBase, PricingList } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { produce } from "immer";
import { useAtomValue } from "jotai/react";
import { useState } from "react";
import { FieldError, Input, NumberField } from "react-aria-components";
import invariant from "tiny-invariant";
import { InfoTooltip, WTooltip } from "../../components/WTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { CurrencyHourly, CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { siteCurrencyAtom } from "../../config.ts";
import { AddEditItemModal } from "./AddEditItemModal.tsx";
import type { PriceFieldsBaseWithType } from "./PricesView.tsx";
import { getResourceTypeInfo, type PricingResourceType } from "./pricingResourceTypes.tsx";

export function PricesTable({
    selectedPrices,
    selectedLocation,
    isDisabled,
    onChangePrices,
}: {
    selectedPrices: PricingList;
    selectedLocation: string;
    isDisabled?: boolean;
    onChangePrices: (prices: PricingList) => void;
}) {
    const siteCurrency = useAtomValue(siteCurrencyAtom);
    const [isEditing, setIsEditing] = useState(false);

    const priceRows: PriceFieldsBaseWithType[] =
        selectedPrices && selectedLocation
            ? selectedPrices[siteCurrency][selectedLocation].flatMap((resource) =>
                  resource.resource_prices.map((price, idx) => ({
                      resource_type: resource.resource_type,
                      num: idx,
                      ...price,
                  })),
              )
            : [];

    function updateRow(value: number, idx: number, resourceType: PricingResourceType) {
        onChangePrices(
            produce(selectedPrices, (prices) => {
                const locData = prices[siteCurrency][selectedLocation];
                const resource = notNull(locData.find((item) => item.resource_type === resourceType));
                invariant(idx >= 0 && idx < resource.resource_prices.length, "Invalid index");
                resource.resource_prices[idx].price_per_unit = value;
            }),
        );
    }

    function updateResource(rows: PriceFieldsBase[], resourceType: string) {
        onChangePrices(
            produce(selectedPrices, (prices) => {
                const locData = prices[siteCurrency][selectedLocation];
                const resource = notNull(locData.find((item) => item.resource_type === resourceType));
                resource.resource_prices = rows;
            }),
        );
    }

    function deleteRow(idx: number, resourceType: PricingResourceType) {
        onChangePrices(
            produce(selectedPrices, (prices) => {
                const locData = prices[siteCurrency][selectedLocation];
                const resource = notNull(locData.find((item) => item.resource_type === resourceType));
                invariant(idx >= 0 && idx < resource.resource_prices.length, "Invalid index");
                resource.resource_prices = resource.resource_prices.filter((_, i) => i !== idx);
            }),
        );
    }

    return (
        <WTable>
            <thead>
                <tr>
                    <th>Item</th>
                    <th className="text-right">Price Per Hour</th>
                    <th className="text-right">
                        Estimated Price per Month
                        <InfoTooltip
                            className="ml-1"
                            text="Monthly prices are estimates, 1 month is approximated to 730 hours."
                        />
                    </th>
                    <th className="text-right">
                        <WButton
                            isDisabled={isDisabled}
                            color="primary"
                            variant="ghost"
                            size="xs"
                            icon="jp-icon-edit"
                            action={() => setIsEditing(!isEditing)}
                        >
                            {isEditing ? "Done" : "Edit"}
                        </WButton>
                    </th>
                </tr>
            </thead>
            <WTableBody>
                {priceRows.map((item, index) => {
                    const resourceType = getResourceTypeInfo(item.resource_type);
                    return (
                        <tr key={index}>
                            <td className="color-primary">
                                <div className="flex items-center gap-0.375em">
                                    <MaskIcon className={`${resourceType?.icon} size-0.75rem`} />
                                    {item.description}
                                </div>
                            </td>
                            <td className="text-right">
                                <div className="w-10rem inline-block">
                                    {isEditing ? (
                                        <NumberField
                                            className={T.NumberField}
                                            onChange={(value: number) => updateRow(value, item.num, item.resource_type)}
                                            value={item.price_per_unit ?? NaN}
                                            aria-label="Set price per hour"
                                            isRequired
                                            formatOptions={{
                                                maximumFractionDigits: 10,
                                            }}
                                        >
                                            <div>
                                                <Input className={T.Input} />
                                            </div>
                                            <FieldError />
                                        </NumberField>
                                    ) : (
                                        <CurrencyHourly value={item.price_per_unit} />
                                    )}
                                </div>
                            </td>
                            <td className="text-right">
                                <CurrencyMonthly value={item.price_per_unit} accurate={true} />
                            </td>
                            <td className="text-right">
                                <div className="flex gap-0.5em justify-end">
                                    {getResourceTypeInfo(item.resource_type).gradual && (
                                        <WModal
                                            button={
                                                <WModalButton
                                                    isDisabled={isDisabled}
                                                    label="Edit Gradual Prices"
                                                    inTable
                                                    icon="jp-graph-bar-icon"
                                                />
                                            }
                                        >
                                            <AddEditItemModal
                                                resourceType={item.resource_type}
                                                prices={selectedPrices[siteCurrency][selectedLocation]}
                                                onChange={(rows, resourceType) => updateResource(rows, resourceType)}
                                            />
                                        </WModal>
                                    )}

                                    <WTooltip text="Delete">
                                        <WButton
                                            isDisabled={isDisabled}
                                            ariaLabel="Delete"
                                            color="primary"
                                            variant="ghost"
                                            size="xs"
                                            icon="jp-trash-icon"
                                            action={() => deleteRow(item.num, item.resource_type)}
                                        />
                                    </WTooltip>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </WTableBody>
        </WTable>
    );
}
