import { useMemo, useState } from "react";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { SchedulePricesSelect } from "./SchedulePricesSelect.tsx";
import { calculateScheduleDates } from "./calculateScheduleDates.tsx";

export function ScheduleNewPricesModal({
    onChange,
    disabledKeys,
}: {
    onChange: (value: string) => void;
    disabledKeys: string[];
}) {
    const items = useMemo(calculateScheduleDates, []);
    const [scheduledDate, setScheduledDate] = useState("");

    return (
        <WModalContent
            title="Schedule New Prices"
            label="Add"
            isActionDisabled={!scheduledDate}
            modalAction={() => onChange(scheduledDate)}
        >
            <div className="max-w-24rem">
                <div className="color-muted pb-1">Starting from</div>

                <SchedulePricesSelect
                    items={items}
                    value={scheduledDate}
                    onChange={(value) => setScheduledDate(value)}
                    disabledKeys={disabledKeys}
                />

                <p className="color-muted pt-2 font-size-small">
                    These prices will take effect on the first day of the selected month at 00:00 UTC.
                </p>
            </div>
        </WModalContent>
    );
}
