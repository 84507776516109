import P from "./PricesView.module.css";

import type { PricingList } from "@warrenio/api-spec/spec.oats.gen";
import { useAtomValue } from "jotai/react";
import { useMemo } from "react";
import { Badge } from "../../components/Badge.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { siteCurrencyAtom } from "../../config.ts";
import { CountryFlag } from "../../modules/location/CountryFlag.tsx";
import { usePricingLocations } from "../../modules/location/query.ts";
import { allHostPoolsQueryAtom } from "../../modules/pools/query.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AddPricesModal } from "./AddPricesModal.tsx";

export function LocationRadios({
    onAdd,
    onChange,
    onDelete,
    isDisabled,
    disabledKeys,
    selectedLocation,
    selectedPrices,
}: {
    onAdd: (value: string) => void;
    onChange: (value: string) => void;
    onDelete: () => void;
    isDisabled?: boolean;
    disabledKeys: string[];
    selectedLocation: string;
    selectedPrices: PricingList;
}) {
    const locations = usePricingLocations();
    const pools = [...useSuspenseQueryAtom(allHostPoolsQueryAtom).values()];
    const siteCurrency = useAtomValue(siteCurrencyAtom);

    const selectedAppliesTo = useMemo(() => {
        const groups: Record<string, string[]> = {};
        if (!selectedPrices) {
            return groups;
        }

        for (const loc of locations) {
            const applyGroup = Object.keys(selectedPrices[siteCurrency]).some((locKey) => locKey === loc.slug)
                ? loc.slug
                : "DEFAULT";
            (groups[applyGroup] ??= []).push(loc.display_name);
        }

        for (const pool of pools) {
            const foundLocation = locations.find((l) => l.slug === pool.location);
            const applyGroup = Object.keys(selectedPrices[siteCurrency]).some(
                (poolKeyword) => poolKeyword === `host_pool:${pool.uuid}`,
            )
                ? `host_pool:${pool.uuid}`
                : foundLocation &&
                    Object.keys(selectedPrices[siteCurrency]).some((locKeyword) => locKeyword === foundLocation.slug)
                  ? foundLocation.slug
                  : "DEFAULT";
            if (applyGroup) {
                (groups[applyGroup] ??= []).push(`${foundLocation?.display_name} - ${pool.name}`);
            }
        }

        return groups;
    }, [selectedPrices, locations, pools, siteCurrency]);

    const locationElements = selectedPrices
        ? Object.keys(selectedPrices[siteCurrency]).map((locKey) => {
              let location = locations.find((s) => s.slug === locKey);
              const pool = pools.find((o) => `host_pool:${o.uuid}` === locKey);
              let title = locKey;
              let icon;

              if (location) {
                  title = location.display_name;
                  icon = <CountryFlag code={location.country_code} />;
              }

              if (pool) {
                  location = locations.find((s) => s.slug === pool.location);
                  title = `${location?.display_name} - ${pool.name}`;
                  icon = <CountryFlag code={location?.country_code} />;
              }

              return (
                  <div
                      key={locKey}
                      className={cn(P.Radio, selectedLocation === locKey && P.isActive)}
                      onClick={() => onChange(locKey)}
                  >
                      {icon}
                      {title}
                  </div>
              );
          })
        : [];

    const appliesTo = !!selectedLocation && selectedAppliesTo[selectedLocation];

    return (
        <>
            <div className="flex items-center pt-4 pb-2">
                {locationElements}

                <WModal
                    button={
                        <WTooltip text="Add Prices">
                            <WButton
                                isDisabled={isDisabled}
                                className={P.AddLocation}
                                color="primary"
                                variant="dashed"
                                size="md"
                                icon="jp-icon-add"
                                action={undefined}
                            />
                        </WTooltip>
                    }
                >
                    <AddPricesModal onChange={(value) => onAdd(value)} disabledKeys={disabledKeys} />
                </WModal>

                <WButton
                    isDisabled={selectedLocation === "DEFAULT" || isDisabled}
                    className={P.DeleteLocation}
                    color="primary"
                    variant="ghost"
                    size="md"
                    icon="jp-trash-icon"
                    action={() => onDelete()}
                >
                    Delete
                </WButton>
            </div>

            <div className="color-muted pb-4">
                Applied to:
                <Badge noDot color="text">
                    {appliesTo ? appliesTo.join(", ") : "[NOT USED]"}
                </Badge>
            </div>
        </>
    );
}
