import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { scheduledDeleteMutation } from "./pricesQuery.ts";

export function DeleteScheduledPricesModal({ item, onDelete }: { item: string; onDelete: () => void }) {
    const deleteScheduledMutation = useStandardMutation(scheduledDeleteMutation);

    async function onDeleteScheduled(item: string) {
        await deleteScheduledMutation.mutateAsync({ id: item });
        onDelete();
    }

    return (
        <WModalContent
            title="Delete Scheduled Prices"
            label="Delete"
            modalAction={async () => await onDeleteScheduled(item)}
        >
            Deleting these scheduled prices means that these prices will be removed and will not be published to live.
        </WModalContent>
    );
}
