import P from "./PricesView.module.css";

import type { PriceFieldsBase, ResourceTypePricesBase } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { FieldError, Input, NumberField } from "react-aria-components";
import { WButton } from "../../components/button/WButton.tsx";
import { default as T } from "../../components/forms/TextField.module.css";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { cn } from "../../utils/classNames.ts";
import { AInput } from "../form/Fields.tsx";
import { getResourceTypeInfo, type PricingResourceType } from "./pricingResourceTypes.tsx";
import { ResourceTypeSelect } from "./ResourceTypeSelect.tsx";

interface DefaultItem {
    hourly: number;
    from_units: number;
}

export function AddEditItemModal({
    resourceType,
    usableTypes,
    prices,
    onChange,
}: {
    resourceType?: PricingResourceType;
    usableTypes?: PricingResourceType[];
    prices?: ResourceTypePricesBase[];
    onChange: (rows: PriceFieldsBase[], resourceType: string) => void;
}) {
    const defaultItem: DefaultItem = {
        hourly: 0,
        from_units: 0,
    };

    const [selectedKey, setSelectedKey] = useState<PricingResourceType | undefined>(
        resourceType ? resourceType : usableTypes?.length ? usableTypes[0] : undefined,
    );
    const selectedInfo = selectedKey ? getResourceTypeInfo(selectedKey) : undefined;

    const priceRows =
        prices && resourceType
            ? prices
                  .filter((item: ResourceTypePricesBase) => item.resource_type === resourceType)
                  .flatMap((resource) =>
                      resource.resource_prices.map((price: PriceFieldsBase) => ({
                          hourly: price.price_per_unit,
                          from_units: selectedInfo?.gradual ? price[selectedInfo.gradual] : 0,
                      })),
                  )
            : [defaultItem];

    const [rows, setRows] = useState(priceRows);

    function addRow() {
        setRows((data) => {
            return [...data, defaultItem];
        });
    }

    function updateRow(value: number, index: number, name: keyof DefaultItem) {
        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            const updatedRow = { ...updatedRows[index] };

            updatedRow[name] = value;
            updatedRows[index] = updatedRow;

            return updatedRows;
        });
    }

    function deleteRow(index: number) {
        setRows((prevRows) => prevRows.filter((_, idx) => idx !== index));
    }

    function onChangeValue() {
        const items = rows.map((row) => {
            const units = selectedInfo?.gradual && row.from_units ? `, from units: ${row.from_units}` : "";
            const description = `${selectedInfo?.title}${units} (title autogenerated later)`;

            const item: PriceFieldsBase = {
                description: description,
                price_per_unit: row.hourly,
            };

            if (selectedInfo?.gradual) {
                item[selectedInfo.gradual] = row.from_units ? row.from_units : 0;
            }

            return item;
        });

        if (selectedKey) {
            onChange(items, selectedKey);
        }
    }

    return (
        <WModalContent
            title={resourceType ? `Edit ${selectedInfo?.title} Gradual Prices` : "Add New Item"}
            label={resourceType ? "Edit" : "Add"}
            modalAction={() => onChangeValue()}
        >
            <div className="pb-2">
                <WTextField wide label="Resource type" onChange={undefined}>
                    {resourceType ? (
                        <AInput value={selectedInfo?.title} readOnly />
                    ) : (
                        <ResourceTypeSelect
                            valueKey={selectedKey}
                            usableTypes={usableTypes ? usableTypes : []}
                            onChange={(item, key) => {
                                console.log("Key: %s, Item: %o", key, item);
                                setSelectedKey(key);
                            }}
                        />
                    )}
                </WTextField>
            </div>

            <WTable
                afterTable={
                    selectedInfo?.gradual ? (
                        <WButton
                            className="w-full"
                            color="primary"
                            size="md"
                            variant="dashed"
                            icon="jp-icon-add"
                            action={() => addRow()}
                        >
                            Add Graduality
                        </WButton>
                    ) : null
                }
            >
                <thead>
                    <tr>
                        {selectedInfo?.gradual && <th>From Units ({selectedInfo.unit})</th>}
                        <th>Price per Hour</th>
                        <th>
                            Estimated Price per Month
                            <InfoTooltip
                                className="ml-1"
                                text="Monthly prices are estimates, 1 month is approximated to 730 hours."
                            />
                        </th>
                        {selectedInfo?.gradual && <th />}
                    </tr>
                </thead>
                <WTableBody>
                    {rows.map((item, index) => (
                        <tr key={index}>
                            {selectedInfo?.gradual && (
                                <td>
                                    <NumberField
                                        value={item.from_units}
                                        onChange={(value) => updateRow(value, index, "from_units")}
                                        className={cn(T.NumberField, P.NumberField)}
                                        defaultValue={NaN}
                                        aria-label="Set from units"
                                        isRequired
                                        formatOptions={{
                                            maximumFractionDigits: 0,
                                        }}
                                    >
                                        <div>
                                            <Input className={T.Input} />
                                        </div>
                                        <FieldError />
                                    </NumberField>
                                </td>
                            )}

                            <td>
                                <NumberField
                                    value={item.hourly}
                                    onChange={(value) => updateRow(value, index, "hourly")}
                                    className={cn(T.NumberField, P.NumberField)}
                                    defaultValue={NaN}
                                    aria-label="Set price per hour"
                                    isRequired
                                    formatOptions={{
                                        maximumFractionDigits: 10,
                                    }}
                                >
                                    <div>
                                        <Input className={T.Input} />
                                    </div>
                                    <FieldError />
                                </NumberField>
                            </td>
                            <td>
                                <CurrencyMonthly value={item.hourly} accurate={true} />
                            </td>
                            {selectedInfo?.gradual && (
                                <td>
                                    {!!index && (
                                        <WButton
                                            ariaLabel="Delete"
                                            color="primary"
                                            variant="ghost"
                                            size="xs"
                                            icon="jp-trash-icon"
                                            action={() => deleteRow(index)}
                                        />
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </WTableBody>
            </WTable>
        </WModalContent>
    );
}
