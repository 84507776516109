import { useState } from "react";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { MonthLongYearDate } from "../../components/l10n/DateFormat.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { NoticeBlock } from "../../components/NoticeBlock.tsx";

export function ChangeCurrentPricesModal({ onChange }: { onChange: (date: string) => void }) {
    const [isChecked, setIsChecked] = useState(false);
    const date = new Date();

    return (
        <WModalContent
            isActionDisabled={!isChecked}
            title="Change Current Prices"
            label="Update"
            modalAction={() => onChange(`${date.getFullYear()}-${date.getMonth() + 1}`)}
        >
            <p className="pb-3">
                Are you sure you want to change the <b>active prices</b> of the <b>current month</b>?
            </p>

            <NoticeBlock full color="error">
                <div className="p-3 font-size-title text-center font-bold text-uppercase">
                    <MonthLongYearDate date={new Date()} />
                </div>
            </NoticeBlock>

            <div className="pt-3 max-w-30rem">
                <WCheckbox isSelected={isChecked} onChange={setIsChecked}>
                    I confirm that changing active month prices will affect all the resources already used from the
                    beginning of the current month.
                </WCheckbox>
            </div>
        </WModalContent>
    );
}
